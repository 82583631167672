import { createSelector } from 'reselect';

export const authTokenSelector = (state) => state.auth.token;
export const authRoleSelector = (state) => state.auth.role;
export const authVerifiedSelector = (state) => state.auth.verified;
export const authEmailSelector = (state) => state.auth.email;
export const authIsGlobalManagerSelector = (state) =>
  state.auth.isGlobalManager;
export const authCountrySelector = (state) => state.auth.country;

export const tokenAdminFetcher = (
  token,
  role,
  isGlobalManager,
  verified,
  email
) => ({
  token,
  role,
  isGlobalManager,
  verified,
  email,
});

export const authSelector = createSelector(
  authTokenSelector,
  authRoleSelector,
  authIsGlobalManagerSelector,
  authVerifiedSelector,
  authEmailSelector,
  tokenAdminFetcher
);
